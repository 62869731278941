body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: rgb(230, 227, 223);
  font-family: "Poppins", sans-serif;
}

.headerred {
  height: 300px;
  background-image: url("https://p4.wallpaperbetter.com/wallpaper/398/874/541/champions-league-stadium-wallpaper-preview.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.headerankleague {
  height: 300px;
  background-image: url("https://wallpapercave.com/wp/MiwvVOU.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.headerliga {
  height: 200px;
  background-image: url("https://p4.wallpaperbetter.com/wallpaper/398/874/541/champions-league-stadium-wallpaper-preview.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.headerliga2 {
  height: 200px;
  background-image: url("https://tse3.mm.bing.net/th?id=OIP.x-BEi6Js1k87uFCf24XSfgHaEK&pid=Api&P=0&h=180");
  background-position: center;
  background-repeat: no-repeat;
}
.cardMenu {
  position: relative;
  bottom: 100px;
}

.cardMenu img {
  height: 50px;
}
.cardMenu p {
}

.cardBooking img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.compslide {
  height: 200px;
  object-fit: cover;
  position: relative;
  bottom: 75px;
  border: 0px;
}
.owl-next {
  position: relative;
  left: 100px;
}

.owl-next h5 {
  color: white;
}

.owl-prev h5 {
  color: white;
}

.owl-prev {
  position: relative;
  right: 100px;
  color: red;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login {
  background-image: linear-gradient(to bottom, #2b2e5a, #454791, #d2e9e5);
  height: 750px;
  border-radius: 0px;
}

.register {
  background-image: linear-gradient(to bottom, #2b2e5a, #454791, #d2e9e5);
  height: 700px;
  border-radius: 0px;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

#bgcardliga {
  background-image: url("https://tse3.mm.bing.net/th?id=OIP.g044_PvH9ny_MAt3-0HBEwHaEK&pid=Api&P=0&h=180");
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

#bgprofil {
  background-image: url("https://img.uefa.com/imgml/uefacom/ucl/fallback.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bgteam {
  background-image: url("https://img.uefa.com/imgml/uefacom/ucl/fallback.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bglawan {
  background-image: url("https://img.uefa.com/imgml/uefacom/ucl/fallback.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bglistpemain {
  background-image: url("https://img.uefa.com/imgml/uefacom/ucl/fallback.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#bgalert {
  background-image: url("https://www.motionthestudio.com/wp-content/uploads/2014/07/geometic-bg-orange.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bgcard {
  background-image: url("https://i.ibb.co/HrfwGjR/Untitled-1.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

#bglistwinrate {
  background-image: url("https://cdn.wallpapersafari.com/32/16/59ktPu.jpg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

#imagebg {
  /* background-image: url("https://4.bp.blogspot.com/-DBOVMVld1vM/W_Db1FY3_UI/AAAAAAAACBQ/qa7s9qvgV_YzHABIUG7qmWsVZL2oaiSEACLcBGAs/s1600/Cristiano%2BRonaldo%2B-%2BReal%2BMadrid%2Bv1.1%2B%25280%2529.png"); */
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}

/* #bgranksolo {
  background-image: url("https://ratumerak.id/adminmmsc/assets/ses2.png");
  width: 100%;
  height: 170px;
  background-repeat: no-repeat;
  background-size: cover;
} */
#rankteam {
  background-image: url("https://img.freepik.com/free-vector/gradient-blue-background_23-2149347096.jpg");
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}

#menurank {
  background-image: url("https://img.freepik.com/free-vector/gradient-blue-background_23-2149347096.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

/* #bgrank {
  background-image: url("https://img5.thuthuatphanmem.vn/uploads/2021/07/09/hinh-nen-san-van-dong-cup-c1_025305307.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
} */
/* 
#bgplay {
  background-image: url("https://img.uefa.com/imgml/uefacom/ucl/fallback.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bgwin {
  background-image: url("https://i.pinimg.com/originals/36/eb/a6/36eba6308f711872ce0b7f353c7f6728.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bgdraw {
  background-image: url("https://wallpapercave.com/wp/wp2564240.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bglost {
  background-image: url("http://3.bp.blogspot.com/-xm-rFHoJrHE/WgDk10P7zaI/AAAAAAAAFCU/2JgXqlm9xZAWzQyEusVT62feKW5tb2NlgCHMYBhgL/s1600/green-background-4a1-verdewall.jpg");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#bggoal {
  background-image: url("https://images.creativemarket.com/0.1.0/ps/2914197/4750/3250/m1/fpnw/wm1/abstract-grey-geometric-background-01-.jpg?1498894058&s=8226f7bf036060548e49c0ecb69d9f7a");
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
} */

#cardprofilteam {
  background: linear-gradient(115deg, #127596, #042678);
  color: #fff;
}

#bggradient1 {
  background: linear-gradient(115deg, #62cff4, #2c67f2);
}

#bggradient2 {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

#btnmmsc {
  background-color: #2b2e5a;
  color: white;
}

#genap {
  background-color: rgba(16, 38, 154, 0.8);
}
#ganjil {
  background-color: rgba(69, 87, 178, 0.8);
}
